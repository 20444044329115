<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { pwaInfo } from 'virtual:pwa-info'
import { ref, watch } from 'vue'

console.log('pwainfo', pwaInfo)

// replaced dyanmicaly
const reloadSW: any = '__RELOAD_SW__'

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    console.log(`Service Worker at: ${swUrl}`)
    if (reloadSW === 'true') {
      r &&
        setInterval(async () => {
          console.log('Checking for sw update')
          await r.update()
        }, 20000 /* 20s for testing purposes */)
    } else {
      console.log(`SW Registered: ${r}`)
    }
  },
})

async function close() {
  offlineReady.value = false
  needRefresh.value = false
  refreshRef.value.close()
}

const refreshRef = ref()

watch(
  needRefresh,
  val => {
    if (val) {
      refreshRef.value.showModal()
    }
  },
  { immediate: true },
)
</script>

<template>
  <Teleport to="body">
    <dialog ref="refreshRef" class="modal modal-bottom sm:modal-middle">
      <div class="modal-box">
        <h3 class="text-lg font-bold">Nova versão disponível</h3>
        <p class="py-4">
          <span v-if="offlineReady"> App ready to work offline </span>
          <span v-else>
            Existe uma nova versão da aplicação, clica em Refresh para
            atualizar.
          </span>
        </p>
        <div class="modal-action">
          <form method="dialog">
            <button class="btn mr-2" @click="close">Close</button>

            <button
              v-if="needRefresh"
              @click="updateServiceWorker()"
              class="btn btn-primary text-white"
            >
              Refresh
            </button>
          </form>
        </div>
      </div>
    </dialog>
  </Teleport>
</template>
