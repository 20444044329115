
import { createI18n } from 'vue-i18n';

export default createI18n({
    locale: 'pt',
    fallbackLocale: 'pt',
    messages: {
      pt: {
        menu: {
          stats: "Ganhos",
          services: "Serviços",
          calendar: "Agenda",
          clients: "Clientes"
        },
        stats: {
          emptyTitle: 'O seu sucesso começa agora!',
          emptyDescription: 'Registe as suas marcações e acompanhe os seus ganhos ao longo do tempo.',
        },
        client: {
          viewTitle: 'Cliente',
          editTitle: 'Editar Cliente',
          createTitle: 'Adicionar Cliente',
          listTitle: 'Clientes',
          name: 'Nome',
          phone: 'Telemóvel',
          notes: 'Notas',
          history: 'Histórico',
          emptyTitle: 'Pronto para crescer?',
          emptyDescription: 'Adicione os seus clientes e mantenha tudo organizado. Uma boa gestão significa mais tempo para o que realmente importa!',
          emptySearchDescription: 'Não tens clients com o nome inserido.'
        },
        service: {
          name: 'Nome',
          price: 'Preço',
          duration: 'Duração',
          newTitle: 'Adicionar Serviço',
          editTitle: 'Editar Serviço',
          listTitle: 'Serviços',
          deleteTitle: 'Delete Service',
          deleteDescription: 'Are you sure you want to delete this service?',
          emptyTitle: 'Vamos começar!',
          emptyDescription: 'Crie os serviços que oferece e simplifique o agendamento. Assim, pode focar-se no que faz melhor!',
          emptySearchDescription: 'Não tens serviços com o nome inserido.'
        },
        appointment: {
          newTitle: 'Nova Marcação',
          editTitle: 'Editar Marcação',
          client: 'Cliente',
          description: 'Descrição',
          descriptionPlaceholder: 'O motivo para esta marcação é...',
          service: 'Serviço',
          starts: 'Início',
          ends: 'Fim',
          time: 'Hora',
          price: 'Preço',
          commission: 'Comissão',
          notes: 'Notas',
          notesPlaceholder: 'Notas sobre esta marcação para consultar mais tarde.',
          deleteTitle: 'Apagar Marcação',
          deleteDescription: 'Tens a certeza de que queres apagar esta marcação?'
        },
        calendar: {
          appointments: 'Marcações',
          schedule: 'Adicionar Marcação',
          noAppointmentsTitle: 'Nada marcado hoje',
          noAppointmentsDescription: 'Parece que hoje está livre. Se algo surgir, pode sempre adicionar uma marcação.'
        },
        forms: {
          save: 'Guardar',
          saving: 'Saving',
          optional: '(opcional)'
        },
        action: {
          edit: 'Editar',
          no: 'Não',
          delete: 'Apagar',
          deleting: 'A apagar'
        },
        placeholder: {
          search: 'Pesquisar...'
        }
      },
    },
  });